import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import toaster from '@meforma/vue-toaster'
import http from './http'
import './index.css'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(store)
  .use(router)
  .use(VueGtag, {
    config: { id: 'G-865PJ3KL4H' }
  })
  .use(toaster)
  .use(http)
  .mount('#app')
